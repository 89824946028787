<template>
  <div class="card" :class="{ 'has-error': isError }">
    <!-- <div>{{ userCriteriaStage }}</div> -->
    <div class="mb-8">
      <div class="title">{{ title }}</div>
      <div class="subtitle-1">
        Критерий как в приказе 203н: {{ titleFor203n }}
      </div>
    </div>
    <div>
      <SelectFeatureNPS
        label="Оцените, пожалуйста, по 9-ти бальной шкале необходимость критерия, где 1 — Не нужен, 9 — Абсолютно необходим"
        hintFrom="необходимость"
        hint="мера обязательности рекомендации пациенту проведения именно этого вмешательства (пациент может отказаться от вмешательства). Абсолютную необходимость можно выразить следующим образом — «это будет грубым нарушением, если врач не предложит пациенту данное вмешательство, и может иметь тяжелые последствия для врача, например увольнение, судебное разбирательство»."
        class="mb-8"
        :value="answers.criteriaNecessity"
        fieldName="criteriaNecessity"
        commentFieldName="necessityComment"
        :disabled="disabled"
        @change="onChange"
        @has-changes="onHasChanges"
        :comment="answers.necessityComment"
      />
      <SelectFeatureNPS
        label="Оцените, пожалуйста, по 9-ти бальной шкале доступность выполнения критерия, где 1 — Недоступен, 9 — Доступен везде"
        hintFrom="доступность"
        hint="Выполнимость медицинского вмешательства во всех медицинских организациях на всей территории РФ, оказывающих медицинскую помощь при данном заболевании/состоянии. В процессе оценки доступности необходимо учитывать: наличие и уровень квалификации медицинских кадров; наличие в регионе необходимых медицинских технологий; своевременность получения медицинской помощи; оптимальное месторасположение лечебного учреждения; уровень стоимости медицинской услуги."
        class="mb-8"
        :value="answers.criteriaAccessability"
        fieldName="criteriaAccessability"
        commentFieldName="accessabilityComment"
        :disabled="disabled"
        @change="onChange"
        @has-changes="onHasChanges"
        :comment="answers.accessabilityComment"
      />
    </div>
    <GvsCardStatus
      class="mb-2"
      :isError="isError"
      :isLoading="isLoading"
      :hasChanges="hasChanges"
      :isReady="isReady"
      :isInitial="isInitial"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import SelectFeatureNPS from '@/components/nmic/SelectFeatureNPS.vue'
import GvsCardStatus from '@/components/gvs/GvsCardStatus.vue'

import { updateCriteriaCard } from '@/lib/requestsAPI'
import { isNMICAnswersReady } from '@/lib/answers'

import { typograph } from '@/lib/typograph.js'

export default {
  props: ['userCriteriaStage', 'disabled'],
  data() {
    return {
      isLoading: false,
      isError: false,
      hasChanges: false,
      answers: {},
      TEMPLATE_NMIC_ANSWERS: {
        criteriaNecessity: 0,
        criteriaAccessability: 0,
        necessityComment: '',
        accessabilityComment: '',
      },
    }
  },
  components: {
    GvsCardStatus,
    SelectFeatureNPS,
  },
  created() {
    this.answers = this.userCriteriaStageAnswers
      ? this.userCriteriaStageAnswers
      : this.TEMPLATE_NMIC_ANSWERS
  },
  watch: {
    isReady: {
      immediate: true,
      handler() {
        this.$emit('ready', {
          index: this.userCriteriaStage.id,
          isReady: this.isReady,
        })
      },
    },
  },
  computed: {
    criterion() {
      return this.userCriteriaStage.criterionStage.criterion || {}
    },
    show() {
      return this.userCriteriaStage.show
    },
    title() {
      const { numInOrder } = this.userCriteriaStage
      return typograph(numInOrder + '. ' + this.criterion.name)
    },
    titleFor203n() {
      return this.criterion.name_203n
        ? typograph(this.criterion.name_203n)
        : 'Критерий отсутствует в действующем приказе 203н'
    },
    userCriteriaStageAnswers() {
      return this.userCriteriaStage.data
    },
    isReady() {
      return isNMICAnswersReady(this.answers)
    },
    isInitial() {
      // Если все ответы falsy вернуть true
      return Object.values(this.answers).every(a => Boolean(a) === false)
    },
    sendingParams() {
      const { users_projects_nosology_id, criteria_stage_id } =
        this.userCriteriaStage
      return { users_projects_nosology_id, criteria_stage_id }
    },
  },
  methods: {
    onHasChanges(evt) {
      this.hasChanges = evt
    },
    onChange({ name, value }) {
      if (this.isError) {
        this.$toast.warning(
          'Ошибка сохранения карточки. Перезагрузите страницу'
        )
        return
      }

      if (this.isLoading) {
        this.$toast.warning('Подождите. Сохраняю прошлые изменения')
        return
      }

      this.isLoading = true
      updateCriteriaCard({
        ...this.sendingParams,
        data: { ...this.answers, [name]: value },
      })
        .then(res => {
          this.answers = res
          this.isLoading = false
          this.hasChanges = false
        })
        .catch(err => {
          this.isError = true
          console.error(err)
          this.$toast.error(
            `Ошибка сохранения карточки ${this.userCriteriaStage.id}`
          )
        })
    },
  },
}
</script>

<style scoped>
.card {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.has-error {
  border-color: red;
  background-color: rgba(255, 0, 0, 0.1);
}
</style>
